/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import React, { useEffect, useState } from "react"
import { Card, Col, Button, Row, Spinner, Form } from "react-bootstrap"
import { RouteComponentProps } from "react-router-dom"
import { useAuth } from "../Auth"
import { useToast } from "../Toast"
import { fetchHistory, downloadCSV } from "../usecases/certification"
import {
  Histories,
  MaybeLoading,
  QueryParams,
  HistoryFilterParams,
  Vendor,
  Asset,
  App
} from "../types"
import HistoryTable from "../components/HistoryTable"
import DatePicker from "../components/DatePicker"
import { OnDatesChangeProps, FocusedInput } from "@datepicker-react/styled"
import { fetchApps } from "../usecases/app"
import { fetchAssets } from "../usecases/asset"
import { fetchVendors } from "../usecases/vendor"
import { FailModal } from "../components/Modal"
import { fmtDate, marshalQueryParams } from "../util"

const MAX_CSV_EXPORT_COUNT = 20000

const initialParams = {
  from: null,
  to: null,
  focusedInput: null
}

const VendorList = (vendors: Vendor[]) => {
  return (
    <React.Fragment>
      {vendors.map(vendor => (
        <option key={vendor.vendorId} value={vendor.vendorId}>
          {vendor.vendorName}
        </option>
      ))}
    </React.Fragment>
  )
}

const AppList = (apps: App[]) => {
  return (
    <React.Fragment>
      {apps.map(app => (
        <option key={app.appCode} value={app.appCode}>
          {app.appName}
        </option>
      ))}
    </React.Fragment>
  )
}

const AssetList = (assets: Asset[]) => {
  return (
    <React.Fragment>
      {assets.map(asset => (
        <option key={asset.assetCode} value={asset.assetCode}>
          {asset.assetName}
        </option>
      ))}
    </React.Fragment>
  )
}

const History: React.FC<RouteComponentProps> = ({ history, location }) => {
  const { token } = useAuth()
  const { createToast } = useToast()
  const [filterParams, setFilterParams] = useState<HistoryFilterParams>(
    initialParams
  )
  const [focusedInput, setFocusedInput] = useState<FocusedInput>(null)
  const [vendors, setVendors] = useState<Vendor[]>([])
  const [isShow, setIsShow] = useState<boolean>(false)
  const [apps, setApps] = useState<App[]>([])
  const [assets, setAssets] = useState<Asset[]>([])
  const [historiesState, setHistoriesState] = useState<MaybeLoading<Histories>>(
    {
      data: null,
      isLoading: true
    }
  )

  useEffect(() => {
    const f = async () => {
      if (!token) {
        return
      }

      const queryParams = marshalQueryParams(location.search)
      const vendors = await fetchVendors(token, { pageNum: -1 })
      setVendors(vendors.vendors)
      const histories = await fetchHistory(token, queryParams)
      setHistoriesState({
        data: histories,
        isLoading: false
      })
      setFilterParams(queryParams)

      if (queryParams.vendorId) {
        const apps = await fetchApps(
          token,
          { pageNum: -1 },
          queryParams.vendorId
        )
        setApps(apps.apps)

        if (queryParams.appCode) {
          const assets = await fetchAssets(
            token,
            queryParams.vendorId,
            queryParams.appCode,
            { pageNum: -1 }
          )
          setAssets(assets.assets)
        }
      }
    }
    f()
  }, [location.search, token])

  const isExportDisabled =
    !historiesState.data ||
    !historiesState.data.count ||
    historiesState.data.count > MAX_CSV_EXPORT_COUNT

  const handleLoad = async (p: QueryParams) => {
    const params = { ...filterParams, ...p }
    const data = await fetchHistory(token, params)

    setHistoriesState({
      data,
      isLoading: false
    })
  }

  const handleDownLoad = async () => {
    try {
      const csvBuf = await downloadCSV(token, filterParams)
      const date = fmtDate(new Date())

      const url = URL.createObjectURL(new Blob([csvBuf]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", `${date}.csv`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    } catch (e: any) {
      createToast(() => () => (
        <FailModal
          show={isShow}
          handleClose={() => setIsShow(false)}
          title="CSVエクスポート"
          message={e.response ? e.response.data.message : ""}
        />
      ))
    }
  }

  const handleDateChange = (e: OnDatesChangeProps) => {
    setFocusedInput(e.focusedInput)
    setFilterParams({
      ...filterParams,
      from: e.startDate,
      to: e.endDate
    })
  }

  const handleVendorChange = async (e: any) => {
    e.persist()
    setApps([])
    setAssets([])
    setFilterParams({
      ...filterParams,
      vendorId: e.target.value
    })

    if (e.target.value !== "*") {
      const apps = await fetchApps(token, { pageNum: -1 }, e.target.value)
      setApps(apps.apps)
    }
  }

  const handleAppChange = async (e: any) => {
    e.persist()
    setAssets([])
    setFilterParams({
      ...filterParams,
      appCode: e.target.value
    })

    if (e.target.value !== "*") {
      const assets = await fetchAssets(
        token,
        filterParams.vendorId || "",
        e.target.value,
        { pageNum: -1 }
      )

      setAssets(assets.assets)
    }
  }

  const handleChange = async (e: any) => {
    e.persist()

    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.value
    })
  }

  return (
    <React.Fragment>
      <Row className="mb-4">
        <Col>
          <h2 className="m-0">取引履歴</h2>
        </Col>
      </Row>

      <Row>
        <Form
          onSubmit={(e: any) => {
            e.preventDefault()
            setHistoriesState({ data: null, isLoading: true })
            handleLoad({})
          }}
        >
          <Row
            className="my-4 p-0"
            css={css`
              max-width: 1280px;
              .filter-option {
                padding: 0px;
                margin: 0px;

                select {
                  width: 120px;
                  height: 35px;
                  margin-left: 10px;
                }

                .col {
                  width: 120px;
                }
              }

              .date-picker-col {
                width: 100%;
                padding: 0px;
                margin-left: 10px;
                label {
                  height: 35px;
                }

                input {
                  width: 190px;
                  padding: 0px;
                  padding-left: 44px;
                  border-radius: 5px;
                }
              }
            `}
          >
            <Col className="ml-3 filter-option">
              <Form.Control as="select" name="type" onChange={handleChange}>
                <option key="all" value="*">
                  全種別
                </option>
                <option key="issue" value="issue">
                  発行
                </option>
                <option key="transfer" value="transfer">
                  移転
                </option>
                <option key="update_seal" value="update_seal">
                  アクティベート
                </option>
              </Form.Control>
            </Col>
            <Col className="filter-option">
              <Form.Control
                as="select"
                name="vendorId"
                value={filterParams.vendorId}
                onChange={handleVendorChange}
              >
                <option key="all" value="*">
                  全ベンダー
                </option>
                {VendorList(vendors)}
              </Form.Control>
            </Col>
            <Col className="filter-option">
              <Form.Control
                as="select"
                name="appCode"
                value={filterParams.appCode}
                onChange={handleAppChange}
                disabled={!filterParams.vendorId}
              >
                <option key="all" value="*">
                  全アプリ
                </option>
                {AppList(apps)}
              </Form.Control>
            </Col>
            <Col className="filter-option">
              <Form.Control
                as="select"
                name="assetCode"
                onChange={handleChange}
                disabled={!filterParams.appCode}
              >
                <option key="all" value="*">
                  全アセット
                </option>
                {AssetList(assets)}
              </Form.Control>
            </Col>
            <Col className="date-picker-col">
              <DatePicker
                filterParams={filterParams}
                handleDateChange={handleDateChange}
                focusedInput={focusedInput}
                setFocusedInput={setFocusedInput}
              />
            </Col>
            <Col>
              <Button
                className="submit-button"
                type="submit"
                disabled={historiesState.isLoading}
              >
                絞り込み
              </Button>
            </Col>
          </Row>
        </Form>
        <Col className="d-flex justify-content-end align-items-center">
          <Button
            variant="outline-secondary"
            onClick={handleDownLoad}
            disabled={isExportDisabled}
          >
            CSVエクスポート
          </Button>
        </Col>
      </Row>

      {!historiesState.isLoading ? (
        <React.Fragment>
          <Card className="mt-3">
            <Card.Body>
              {HistoryTable(historiesState.data, handleLoad)}
            </Card.Body>
          </Card>
        </React.Fragment>
      ) : (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <Spinner animation="border" role="status" />
        </div>
      )}

      <Button
        className="my-4"
        variant="outline-secondary"
        onClick={() => history.goBack()}
      >
        戻る
      </Button>
    </React.Fragment>
  )
}

export default History
