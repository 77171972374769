import React, { useEffect, useState } from "react"
import { RouteComponentProps } from "react-router"
import { useAuth } from "../Auth"
import { useToast } from "../Toast"
import { toJST } from "../util"
import { Assets, App, MaybeLoading, QueryParams } from "../types"
import { Spinner, Col, Button, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import { FailModal } from "../components/Modal"
import { fetchAssets } from "../usecases/asset"
import { fetchApp } from "../usecases/app"
import BaseTable from "../components/BaseTable"
import { TableColumn } from "react-data-table-component"

type ownState = {
  app: App
  assets: Assets
}

interface AssetDataRow {
  assetCode: string
  assetName: string
  registeredDate: string
}

const ListAssetPage: React.FC<
  RouteComponentProps<{ vendorId: string; appCode: string }>
> = ({ match, history }) => {
  const { token } = useAuth()
  const { createToast, isShow, setIsShow } = useToast()
  const [state, setState] = useState<MaybeLoading<ownState>>({
    data: null,
    isLoading: true
  })
  const { vendorId, appCode } = match.params

  useEffect(() => {
    const f = async () => {
      if (!token) {
        return
      }

      try {
        const assets = await fetchAssets(token, vendorId, appCode, {})
        const app = await fetchApp(token, vendorId, appCode)

        setState({
          data: {
            app,
            assets
          },
          isLoading: false
        })
      } catch (error: any) {
        const title = "アプリ一覧情報取得"
        const errorMessage = error.response.data.message

        setIsShow(true)
        createToast(() => () => (
          <FailModal
            show={isShow}
            handleClose={() => setIsShow(false)}
            title={title}
            message={errorMessage}
          />
        ))
      }
    }

    f()
    // eslint-disable-next-line
  }, [appCode, createToast, isShow, setIsShow, token, vendorId])

  if (state.isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status" />
      </div>
    )
  }

  const { app, assets } = state.data

  const rows = assets.assets.map(asset => {
    const { assetCode, assetName, registeredDate } = asset

    return {
      assetCode,
      assetName,
      registeredDate: toJST(registeredDate),
    }
  })
  const buttons = (row: AssetDataRow) => (
    <>
      <Link to={`/vendors/${vendorId}/apps/${appCode}/assets/${row.assetCode}`}>
        <Button>詳細</Button>
      </Link>
    </>
  )

  const columns: TableColumn<AssetDataRow>[] = [
    {
      name: "アセットコード",
      selector: row => row.assetCode,
//        selector: "assetCode",
      sortable: true
    },
    {
      name: "アセット名",
      selector: row => row.assetName,
//       selector: "assetName",
      sortable: true
    },
    {
      name: "登録日",
      selector: row => row.registeredDate,
//        selector: "registeredDate",
      sortable: true
    },
    {
      name: "詳細",
      cell: (row) => buttons(row)
//        selector: "detailAsset",
    }
  ]

  const handleLoad = async (params: QueryParams) => {
    const assets = await fetchAssets(token, vendorId, appCode, params)

    setState({
      data: {
        app: state.data.app,
        assets
      },
      isLoading: false
    })
  }

  return (
    <>
      <Row className="mb-4">
        <Col>
          <h2 className="m-0">{app.appName}アセット一覧</h2>
        </Col>
      </Row>

      <BaseTable<AssetDataRow>
        data={{
          columns: columns,
          data: rows
        }}
        count={assets.count}
        handleLoad={handleLoad}
      />

      <Button
        className="mt-4"
        variant="outline-secondary"
        onClick={() => history.goBack()}>
        戻る
      </Button>
    </>
  )
}

export default ListAssetPage
