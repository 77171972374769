/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import React from "react"
import ReactPaginate from "react-paginate"

type OwnProps = {
    rowsPerPage: number
    rowCount: number
    onChangePage: (e: { selected: number }) => void
    currentPage: number
}

const Pagination: React.FC<OwnProps> = ({
    rowsPerPage,
    rowCount,
    onChangePage,
    currentPage
}) => {
    const pageCount = rowCount % rowsPerPage === 0 ? rowCount / rowsPerPage : Math.floor(rowCount / rowsPerPage) + 1
    const from = currentPage * rowsPerPage || 1
    const to = (currentPage + 1) * rowsPerPage > rowCount ? rowCount : currentPage * rowsPerPage + rowsPerPage

    return (
        <div className="mt-4 d-flex justify-content-center"
            css={css`
                .display-info {
                    position: absolute;
                    margin-top: 5px;
                    left: 15px;
                }
            `}
        >
            <div className="display-info">表示中: {from} ~ {to} ：全 {rowCount} 個中</div>
            <ReactPaginate
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                forcePage={currentPage}
                onPageChange={(e) => onChangePage(e)}
                containerClassName='pagination'
                pageClassName='page-item'
                pageLinkClassName='page-link'
                activeClassName='active'
                previousLabel='前ページ'
                nextLabel='次ページ'
                previousClassName='page-item'
                nextClassName='page-item'
                previousLinkClassName='page-link'
                nextLinkClassName='page-link'
                disabledClassName='disabled'
                breakLabel='...'
                breakClassName='page-item'
                breakLinkClassName='page-link'
            />
        </div>
    )
}

export default Pagination
