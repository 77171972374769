import React, { ChangeEvent } from "react"
//import { FormControlProps } from "react-bootstrap"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
//import { ReplaceProps, BsPrefixProps } from "react-bootstrap/helpers"
import Row from "react-bootstrap/Row"

type OwnProps = {
  label: string
  name: string
  placeholder?: string
  value: string
  isInvalid?: boolean
  message?: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  /*
  onChange: (
    event: React.FormEvent<
      ReplaceProps<"input", BsPrefixProps<"input"> & FormControlProps>
    >
  ) => void
   */
  required?: boolean
  readOnly?: boolean
}

const fg: React.FC<OwnProps> = ({
  label,
  name,
  placeholder,
  value,
  isInvalid,
  message,
  onChange,
  required,
  readOnly
}) => {
  return (
    <Form.Group as={Row} controlId={label}>
      <Form.Label column sm={2}>
        {label}
        {required && (
          <span className="text-danger float-right mr-5">(必須)</span>
        )}
      </Form.Label>
      <Col sm={4}>
        <Form.Control
          readOnly={readOnly}
          type="text"
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          isInvalid={isInvalid}></Form.Control>
        <Form.Control.Feedback type="invalid">{message}</Form.Control.Feedback>
      </Col>
    </Form.Group>
  )
}

export default fg
