import React from "react"
import { Button, Modal } from "react-bootstrap"

type ownProps = {
  title: string
  message?: string
  show: boolean
  handleClose: () => void
}

export const SuccessModal = (props: ownProps) => {
  return (
    <Modal show onHide={() => props.handleClose()}>
      <Modal.Header>
        <Modal.Title>{props.title}完了</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{props.title}が完了しました</p>
        {props.message && <p>{props.message}</p>}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.handleClose()}>
          閉じる
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export const FailModal = (props: ownProps) => {
  const message = (message => {
    switch (message) {
      case "jwt expired":
        return "リロードしてください"
      default:
        return message
    }
  })(props.message)

  return (
    <Modal show onHide={() => props.handleClose()}>
      <Modal.Header>
        <Modal.Title>{props.title}失敗</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{props.title}に失敗しました</p>
        {message && <p>{message}</p>}
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => props.handleClose()} variant="secondary">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
