import React from "react"
import { Link } from "react-router-dom"
import { Histories, LoadFunction } from "../types"
import { toJST, currencyToMessage } from "../util"
import BaseTable from "./BaseTable"
import { TableColumn } from "react-data-table-component"

interface HistoryDataRow {
  type: string
  vendorName: string
  appName: string
  assetName: string
  assetSeq: number
  price: string
  registeredDate: string
  certId: string,
  detailCert: string
//  appCode: string
//  assetCode: string
}

const HistoryTable = ({ histories, count }: Histories, handleLoad: LoadFunction) => {
  const rows = histories.map(history => {
    const {
      type,
      vendorName,
      appName,
      assetName,
      assetSeq,
      price,
      registeredDate,
      certId
    } = history

    const ellipsisDiv = (content: string) => (
      <div className="baseEllipsis pt-2">
        <p className="ellipsisContent">
          <Link to={`/certifications/${content}`}>{content}</Link>
        </p>
      </div>
    )

    const detailCert = ellipsisDiv(certId)

    return {
      type,
      vendorName,
      appName,
      assetName,
      assetSeq,
      price: price ? `${price} ${currencyToMessage(history.currency)}` : " ",
      registeredDate: toJST(registeredDate),
      detailCert
    }
  })

  const columns: TableColumn<HistoryDataRow>[] = [
    {
      name: "種別",
//        selector: "type",
      selector: row => row.type,
    },
    {
      name: "ベンダー名",
//        selector: "vendorName",
      selector: row => row.vendorName,
    },
    {
      name: "アプリ名",
//        selector: "appName",
      selector: row => row.appName,
    },
    {
      name: "アセット名",
//        selector: "assetName",
      selector: row => row.assetName,
    },
    {
      name: "証明書番号",
//        selector: "assetSeq",
      selector: row => row.assetSeq,

      width: "75px"
    },
    {
      name: "金額",
//        selector: "price",
      selector: row => row.price,
      minWidth: "80px",
      sortable: true
    },
    {
      name: "日時",
//        selector: "registeredDate",
      selector: row => row.registeredDate,
      sortable: true
    },
    {
      name: "証明書",
//        selector: "detailCert",
      selector: row => row.detailCert,
      minWidth: "600px"
    }
  ]

  return (
    <BaseTable<HistoryDataRow>
      data={{
        columns: columns,
        data: rows
      }}
      count={count}
      handleLoad={handleLoad}
    />
  )
}

export default HistoryTable
