import React, { Dispatch, SetStateAction } from "react"
import { HistoryFilterParams } from "../types"
import { DateRangeInput, OnDatesChangeProps, FocusedInput, DateRangeInputPhrases } from "@datepicker-react/styled"
import { ThemeProvider } from "styled-components"

type OwnProps = {
    filterParams: HistoryFilterParams
    handleDateChange: (e: OnDatesChangeProps) => void
    focusedInput: FocusedInput
    setFocusedInput: Dispatch<SetStateAction<FocusedInput>>
}

const phrases: DateRangeInputPhrases = {
    datepickerStartDatePlaceholder: "開始日",
    datepickerStartDateLabel: "開始日",
    datepickerEndDateLabel: "終了日",
    datepickerEndDatePlaceholder: "終了日",
    resetDates: "リセット",
    close: "閉じる",
    startDateAriaLabel: "yyyy/mm/dd",
    endDateAriaLabel: "yyyy/mm/dd",
    startDatePlaceholder: "yyyy/mm/dd",
    endDatePlaceholder: "yyyy/mm/dd",
}

const DatePicker: React.FC<OwnProps> = ({filterParams, handleDateChange, focusedInput, setFocusedInput}) => {
    return (
        <ThemeProvider
            theme={{
                breakpoints: ["32em", "48em", "64em"],
                reactDatepicker: {
                    daySize: [36, 40],
                    fontFamily: "system-ui, -apple-system",
                    dateRangeZIndex: 10,
                    inputLabelMargin: 0,
                    inputLabelBorderRadius: 5,
                    inputMinHeight: 33,
                    dateRangeBorderRadius: 5
                }
            }}
            >
            <DateRangeInput
                displayFormat="yyyy/MM/dd"
                onDatesChange={handleDateChange}
                onFocusChange={setFocusedInput}
                startDate={filterParams.from} 
                endDate={filterParams.to} 
                focusedInput={focusedInput}
                phrases={phrases}
                vertical
            />
        </ThemeProvider>
      )

}

export default DatePicker