import * as Yup from "yup"

const setttings = {
  vendorId: Yup.string()
    .required("値を入力してください")
    .test(
      "validate space",
      "空白が含まれています",
      (value: string) => !value.includes(" ")
    ),
  vendorName: Yup.string().required("値を入力してください"),
  staffName: Yup.string().required("値を入力してください"),
  staffEmail: Yup.string()
    .required("値を入力してください")
    .email("メールアドレスを入力してください"),
  appCode: Yup.string()
    .required("値を入力してください")
    .matches(/^[0-9A-Za-z_-]+$/, "英数字以外が含まれています"),
  appName: Yup.string().required("値を入力してください"),
  priceFlag: Yup.string()
    .required("値を入力してください")
    .oneOf(["0", "1"], "値は0か1のみです"),
  seqFlag: Yup.string()
    .required("値を入力してください")
    .oneOf(["0", "1"], "値は0か1のみです"),
  sealFlag: Yup.string()
    .required("値を入力してください")
    .oneOf(["0", "1"], "値は0か1のみです"),
  sealPrefix: Yup.string()
    .length(5, "５文字にしてください")
    .matches(/^[A-Z0-9]{5}$/, "英大文字数字以外が含まれています"),
  sealToUserFlag: Yup.string()
    .required("値を入力してください")
    .oneOf(["0", "1"], "値は0か1のみです"),
  issuer: Yup.string().required("値を入力してください"),
  infoUrl: Yup.string().url("URLを入力してください")
}

export default setttings