import { App, Apps, QueryParams } from "./../types"
import { axiosWithToken, convertQueryParams } from "../util"

export const fetchApp = async (
  token: string,
  vendorId: string,
  appCode: string
): Promise<App> => {
  const res = await axiosWithToken(token).get(
    `/admin/apps/${vendorId}/${appCode}`
  )
  return res.data
}

export const fetchApps = async (
  token: string,
  params: QueryParams,
  vendorId?: string
): Promise<Apps> => {
  const queryParams = convertQueryParams(params)
  const res = await axiosWithToken(token).get(`/admin/apps/${vendorId ?? ""}?` + queryParams)
  return res.data
}

export const addApp = async (
  token: string,
  vendorId: string,
  appCode: string,
  appName: string,
  issuer: string,
  priceFlag: string,
  seqFlag: string,
  sealFlag: string,
  sealPrefix: string,
  sealToUserFlag: string,
  seriesFlag: string,
  infoUrl: string,
): Promise<App> => {
  const data = {
    vendorId,
    appCode,
    appName,
    issuer,
    priceFlag,
    seqFlag,
    sealFlag,
    sealPrefix,
    sealToUserFlag,
    seriesFlag,
    infoUrl
  }
  const res = await axiosWithToken(token).post("/admin/apps", data)
  return res.data
}

export const updateApp = async (
  token: string,
  vendorId: string,
  appCode: string,
  appName: string,
  issuer: string,
  priceFlag: string,
  seqFlag: string,
  sealFlag: string,
  sealPrefix: string,
  sealToUserFlag: string,
  seriesFlag: string,
  infoUrl: string,
): Promise<App> => {
  const data = {
    vendorId,
    appCode,
    appName,
    issuer,
    priceFlag,
    seqFlag,
    sealFlag,
    sealPrefix,
    sealToUserFlag,
    seriesFlag,
    infoUrl
  }
  const res = await axiosWithToken(token).put(
    `/admin/apps/${vendorId}/${appCode}`,
    data
  )
  return res.data
}

export const fetchAssetCount = async (token: string, vendorId: string, appCode: string): Promise<number> => {
  const res = await axiosWithToken(token).get(`/admin/apps/${vendorId}/${appCode}/count`)
  return res.data ? res.data.count : 0
}
