import { Vendor, Vendors, QueryParams } from "../types"
import { axiosWithToken, convertQueryParams } from "./../util"
import { AxiosResponse } from "axios"

export const fetchVendor = async (
  token: string,
  vendorId: string
): Promise<Vendor> => {
  const res = await axiosWithToken(token).get(`/admin/vendors/${vendorId}`)
  return res.data
}

export const fetchVendors = async (token: string, params: QueryParams): Promise<Vendors> => {
  const queryParams = convertQueryParams(params)
  const res: AxiosResponse<Vendors> | void = await axiosWithToken(token).get("/admin/vendors?" + queryParams)

  if (!res) {
    return { vendors: [], count: 0 }
  }

  return res.data
}

export const addVendor = async (
  token: string,
  vendorId: string,
  vendorName: string,
  staffName: string,
  staffEmail: string
): Promise<Vendor> => {
  const data = {
    vendorId,
    vendorName,
    staffName,
    staffEmail
  }
  const res = await axiosWithToken(token).post("/admin/vendors", data)
  return res.data
}

export const updateVendor = async (
  token: string,
  vendorId: string,
  vendorName: string,
  staffName: string,
  staffEmail: string
): Promise<Vendor> => {
  const data = {
    vendorId,
    vendorName,
    staffName,
    staffEmail
  }
  const res = await axiosWithToken(token).put(
    `/admin/vendors/${vendorId}`,
    data
  )
  return res.data
}
