/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import React, { useState } from "react"
import DataTable, { TableColumn } from "react-data-table-component"
import { LoadFunction } from "../types"
import Pagination from "./Pagination"
import Spinner from "react-bootstrap/Spinner"

/*
type OwnProps = {
  data: IDataTableProps<any>
  handleLoad: LoadFunction
  count: number
}
*/
type OwnProps<T> = {
//  columns: TableColumn<T>[]
  data: {
    columns: TableColumn<T>[],
    data: any
  }
  count: number
//  isLoaded: boolean
  handleLoad: LoadFunction
}

//const BaseTable: React.FC<OwnProps> = ({ data, count, handleLoad }) => {
function BaseTable<T>(props: OwnProps<T>): JSX.Element {
  const [page, setPage] = useState(0)
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [item, setItem] = useState<string>("")
  const [order, setOrder] = useState<"desc" | "asc">("desc")

  const load = (
    page: number,
    pageNum: number,
    item: string,
    order: "desc" | "asc"
  ) => {
    setIsTableLoading(true)

    props.handleLoad({
      page,
      pageNum,
      item,
      order
    })

    setIsTableLoading(false)
    setPage(page)
  }

  const handleChange = (e: {selected: number }) => {
    setPage(e.selected)
    load(e.selected, rowsPerPage, item, order)
  }
  
  const pagination = () => <Pagination 
    rowCount={props.count || 0}
    rowsPerPage={rowsPerPage}
    currentPage={page}
    onChangePage={handleChange}
  />

  return (
    <div css={css`
    .row-page-select {
      p {
        padding-top: 5px;
        margin-right: 10px;
      }

      select {
        background: white;
        height: calc(1.5em + .5rem + 2px);
        padding-top: .25rem;
        padding-bottom: .25rem;
        padding-left: .5rem;
        font-size: .875rem;
      }

      select option {
        padding-top: .25rem;
        padding-bottom: .25rem;
        padding-left: .5rem;
        font-size: .875rem;
      }
    }


    div:first-of-type {
      height: auto;
    }

    .rdt_TableCol {
      height: auto;
    }

    .rdt_TableCol {
      font-weight: bold;
      font-size: 14px;
    }

    .rdt_TableCol, 
    .rdt_TableCell {
      font-size: 14px;
      border: 1px solid #dee2e6;
    }
  `}>
      {<div className="d-flex row-page-select mt-4">
        <label>表示個数
        <select id="display-count" onChange={async (e) => {
          setRowsPerPage(parseInt(e.currentTarget.value))
          load(0, parseInt(e.currentTarget.value), item, order)
        }}>
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select></label>
      </div>}
      <DataTable
        striped
        noHeader
        noDataComponent={NoRecordComponent}
        columns={props.data.columns}
        data={props.data.data}
        className="mt-3"
        onSort={async (e) => {
          if (!e.selector || typeof e.selector != "string") return

          load(0, rowsPerPage, e.selector, order === "desc" ? "asc" : "desc")
          setItem(e.selector)
          setOrder(order === "desc" ? "asc" : "desc")
        }}
        progressComponent={ProgressSpinner}
        progressPending={isTableLoading}
        pagination
        paginationServer
        paginationComponent={pagination}
      />
    </div>
  )
}

const NoRecordComponent = <div>データが見つかりませんでした</div>
const ProgressSpinner = (
  <Spinner
    animation="border"
    role="status"
    className="mt-4"
    css={css`
          width: 30px !important;
          height: 30px !important;
        `}
  />
)

export default BaseTable
