/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import React from "react"

const NotFoundPage: React.FC = () => {
  return (
    <h1
      className="text-center"
      css={css`
        margin-top: 64px;
      `}>
      お探しのページは見つかりませんでした。
    </h1>
  )
}

export default NotFoundPage
