import React, { useEffect, useState } from "react"
import { Button, Col, Spinner, Row } from "react-bootstrap"
import { Link, RouteComponentProps } from "react-router-dom"
import { useAuth } from "../Auth"
import { useToast } from "../Toast"
import { toJST } from "../util"
import { Vendor, App, Apps, MaybeLoading, QueryParams } from "../types"
import { fetchApps } from "../usecases/app"
import { fetchVendor } from "../usecases/vendor"
import BaseTable from "../components/BaseTable"
import { TableColumn } from "react-data-table-component"

type ownState = {
  vendor: Vendor
  apps: Apps
}

interface AppDataRow {
  appCode: string
  appName: string
  vendorId: string
  startDate: string
  endDate: string
  latestActivityDate: string
  registeredDate: string
}

const ListAppPage: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
  history
}) => {
  const { token } = useAuth()
  const { createToast, isShow, setIsShow } = useToast()
  const [state, setState] = useState<MaybeLoading<ownState>>({
    data: null,
    isLoading: true
  })
  const [hasError, setHasError] = useState(false)

  const vendorId = match.params.id

  useEffect(() => {
    const f = async () => {
      if (!token) {
        return
      }

      try {
        const vendor = await fetchVendor(token, vendorId)
        const apps = await fetchApps(token, {}, vendorId)

        setState({
          data: {
            vendor,
            apps
          },
          isLoading: false
        })
      } catch (error) {
        setHasError(true)
      }
    }

    f()
  }, [createToast, isShow, setIsShow, token, vendorId])

  if (hasError) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h2>エラーが発生しました。</h2>
        <h3>原因調査中です。</h3>
        <h3>ご迷惑をおかけして申し訳ありません。</h3>
      </div>
    )
  }

  if (state.isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status" />
      </div>
    )
  }

  const { vendor, apps } = state.data
//  console.log(apps.apps)

  const rows = apps.apps.map((app: App) => {
    const { appName, registeredDate, appCode } = app

    const startDate = app.startDate ? app.startDate : " "
    const endDate = app.endDate ? app.endDate : " "
    const latestActivityDate = app.latestActivityDate ? toJST(app.latestActivityDate) : ""

    return {
      appCode,
      appName,
      vendorId,
      startDate,
      endDate,
      latestActivityDate,
      registeredDate: toJST(registeredDate),
    }
  })

  const buttons = (row: AppDataRow) => (
    <>
      <Link
        key="listAsset"
        to={`/vendors/${vendorId}/apps/${row.appCode}/assets`}
        className="mr-3">
        <Button size="sm" variant="success">
          アセット一覧
        </Button>
      </Link>
      <Link
        key="updateAsset"
        to={`/vendors/${vendorId}/apps/${row.appCode}/edit`}
        className="mr-3">
        <Button size="sm" variant="warning">
          修正
        </Button>
      </Link>
      <Link
        key="detailApp"
        to={`/vendors/${vendorId}/apps/${row.appCode}`}
        className="mr-3">
        <Button size="sm" variant="primary">
          詳細
        </Button>
      </Link>
    </>
  )
  
  const columns: TableColumn<AppDataRow>[] = [
    {
      name: "アプリコード",
      selector: row => row.appCode,
//      selector: "appCode",
      sortable: true,
    },
    {
      name: "アプリ名",
      selector: row => row.appName,
//      selector: "appName",
      sortable: true,
    },
    {
      name: "ベンダーID",
      selector: row => row.vendorId,
//      selector: "vendorId",
    },
    {
      name: "最終取引日時",
      selector: row => row.latestActivityDate,
//      selector: "latestActivityDate",
      sortable: true,
    },
    {
      name: "登録日",
      selector: row => row.registeredDate,
//      selector: "registeredDate",
      sortable: true,
    },
    {
      name: "",
//      selector: "buttons",
      cell: (row) => buttons(row),
      width: "17.8em",
      //grow: 3,
    }
  ]

  const handleLoad = async (params: QueryParams) => {
    const apps = await fetchApps(token, params, vendorId)

    setState({
      data: {
        vendor: state.data.vendor,
        apps
      },
      isLoading: false
    })
  }

  return (
    <>
      <Row className="mb-4">
        <Col>
          <h2 className="m-0">{vendor.vendorName}アプリ一覧</h2>
        </Col>
        <Col>
          <Link to={`/vendors/${vendorId}/add_app`}>
            <Button className="float-right">
              <p className="d-inline-block ml-0 mb-0">アプリを追加</p>
            </Button>
          </Link>
        </Col>
      </Row>

      <BaseTable<AppDataRow>
        data={{
          columns: columns,
          data: rows
        }}
        count={apps.count}
        handleLoad={handleLoad}
      />

      <Button
        className="mt-4"
        variant="outline-secondary"
        onClick={() => history.goBack()}>
        戻る
      </Button>
    </>
  )
}

export default ListAppPage
