import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import { Certs, Cert, LoadFunction } from "../types"
import { toJST } from "../util"
import BaseTable from "./BaseTable"
import { TableColumn } from "react-data-table-component"

interface CertDataRow {
  certId: string
  assetSeq: number
  userId: string
  registeredDate: string
  detailCert: string
//  appCode: string
//  assetCode: string
}

const ListCertTblForAsset = ({ certs, count }: Certs, handleLoad: LoadFunction) => {
  const rows = certs.map((cert: Cert) => {
    const { registeredDate, assetSeq, userId, certId } = cert

    const detailCert = (
      <Link to={`/certifications/${certId}`}>
        <Button size="sm">詳細</Button>
      </Link>
    )

    return {
      certId,
      assetSeq,
      userId,
      registeredDate: toJST(registeredDate),
      detailCert
    }
  })

  const columns: TableColumn<CertDataRow>[] = [
    {
      name: "証明書ID",
//        selector: "certId",
      selector: row => row.certId,
    },
    {
      name: "証明書番号",
//        selector: "assetSeq",
      selector: row => row.assetSeq,
      maxWidth: "200px"
    },
    {
      name: "所有者ID",
//        selector: "userId",
      selector: row => row.userId,
    },
    {
      name: "発行日",
//        selector: "registeredDate",
      selector: row => row.registeredDate,
      width: "200px"
    },
    {
      name: "",
//        selector: "detailCert",
      selector: row => row.detailCert,
      width: "100px"
    }
  ]

  return <BaseTable<CertDataRow>
    data={{
      columns: columns,
      data: rows
    }}
    count={count}
    handleLoad={handleLoad}
  />
}

export default ListCertTblForAsset
