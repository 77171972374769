import * as Yup from "yup"
import validators from "./common"

const vendorValidator = Yup.object({
  vendorId: validators.vendorId,
  vendorName: validators.vendorName,
  staffName: validators.staffName,
  staffEmail: validators.staffEmail
})

export default vendorValidator
