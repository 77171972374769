import React, { useEffect, useState } from "react"
import { Card, Col, Row, Form, Button, Spinner } from "react-bootstrap"
import { RouteComponentProps } from "react-router-dom"
import { useAuth } from "../Auth"
import { useToast } from "../Toast"
import { MaybeLoading, Vendor } from "../types"
import { SuccessModal, FailModal } from "../components/Modal"
import { fetchVendor, updateVendor } from "../usecases/vendor"

const EditVendorPage: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
  history
}) => {
  const { token } = useAuth()
  const { createToast, isShow, setIsShow } = useToast()

  const vendorId = match.params.id
  const [state, setState] = useState<MaybeLoading<Vendor>>({
    data: null,
    isLoading: true
  })
  const [validated, setValidated] = useState(false)

  useEffect(() => {
    const f = async () => {
      if (!token) {
        return
      }

      try {
        const vendor = await fetchVendor(token, vendorId)

        setState({
          data: vendor,
          isLoading: false
        })
      } catch (error: any) {
        const errorMessage = error.response.data.message
        const title = "ベンダー一覧情報取得"

        setIsShow(true)
        createToast(() => () => (
          <FailModal
            show={isShow}
            handleClose={() => setIsShow(false)}
            title={title}
            message={errorMessage}
          />
        ))
      }
    }

    f()
  }, [createToast, isShow, setIsShow, token, vendorId])

  if (state.isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status" />
      </div>
    )
  }

  const submit = async (event: any) => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()

    if (form.checkValidity() === false) {
      setValidated(true)
      return
    }

    const vendor = state.data

    const title = "ベンダー情報修正"

    try {
      await updateVendor(
        token,
        vendorId,
        vendor.vendorName,
        vendor.staffName,
        vendor.staffEmail
      )
      history.push("/")

      setIsShow(true)
      createToast(() => () => (
        <SuccessModal
          show={isShow}
          handleClose={() => setIsShow(false)}
          title={title}
        />
      ))
    } catch (error: any) {
      const errorMessage = error.response.data.message

      setIsShow(true)
      createToast(() => () => (
        <FailModal
          show={isShow}
          handleClose={() => setIsShow(false)}
          title={title}
          message={errorMessage}
        />
      ))
    }
  }

  const setVendorName = (vendorName: string) => {
    setState({
      ...state,
      data: {
        ...state.data,
        vendorName
      }
    })
  }

  const setStaffName = (staffName: string) => {
    setState({
      ...state,
      data: {
        ...state.data,
        staffName
      }
    })
  }

  const setStaffEmail = (staffEmail: string) => {
    setState({
      ...state,
      data: {
        ...state.data,
        staffEmail
      }
    })
  }

  const vendor = state.data

  return (
    <>
      <Row className="mb-4">
        <Col>
          <h2 className="m-0">ベンダー修正</h2>
        </Col>
      </Row>
      <Card>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={submit}>
            <Form.Group as={Row} controlId="formVendorId">
              <Form.Label column sm={2}>
                ベンダーID
                <span className="text-danger float-right mr-5">(必須)</span>
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  required
                  type="text"
                  name="vendorId"
                  readOnly
                  defaultValue={vendorId}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formAppName">
              <Form.Label column sm={2}>
                ベンダー名
                <span className="text-danger float-right mr-5">(必須)</span>
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  required
                  type="text"
                  name="vendorName"
                  placeholder="ベンダー名"
                  value={vendor.vendorName}
                  onChange={(e: any) => setVendorName(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  値を入力してください
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formStaffName">
              <Form.Label column sm={2}>
                担当者名
                <span className="text-danger float-right mr-5">(必須)</span>
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  required
                  type="text"
                  name="staffName"
                  placeholder="担当者名"
                  value={vendor.staffName}
                  onChange={(e: any) => setStaffName(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  値を入力してください
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formStaffEmail">
              <Form.Label column sm={2}>
                担当者Email
                <span className="text-danger float-right mr-5">(必須)</span>
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  required
                  type="email"
                  name="staffEmail"
                  placeholder="担当者Email"
                  value={vendor.staffEmail}
                  onChange={(e: any) => setStaffEmail(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  値を入力してください
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Row>
              <Col sm={{ span: 2, offset: 2 }}>
                <Button type="submit" variant="primary">
                  更新
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <Button
        className="mt-4"
        variant="outline-secondary"
        onClick={() => history.goBack()}>
        戻る
      </Button>
    </>
  )
}

export default EditVendorPage
