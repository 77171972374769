import auth0 from "auth0-js"
import React from "react"
import { RouteComponentProps } from "react-router"
import { Col, Button, Spinner, Row } from "react-bootstrap"
import { SuccessModal } from "../components/Modal"
import { useAuth } from "../Auth"
import { useToast } from "../Toast"

const webAuth = new auth0.WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN || "",
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID || ""
})

const ProfilePage: React.FC<RouteComponentProps> = ({ history }) => {
  const { user } = useAuth()
  const { createToast, isShow, setIsShow } = useToast()
  //console.log("user", user)

  if (!user) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status" />
      </div>
    )
  }

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    webAuth.changePassword(
      {
        connection: process.env.REACT_APP_AUTH0_CONNECTION || "",
        email: user.email
      },
      (err: any) => {
        if (!err) {
          setIsShow(true)
          createToast(() => () => (
            <SuccessModal
              show={isShow}
              handleClose={() => setIsShow(false)}
              title={"パスワード変更メール送信"}
            />
          ))
        }
      }
    )
  }

  return (
    <>
      <Row className="mb-4">
        <Col>
          <h2 className="m-0">プロフィール</h2>
        </Col>
      </Row>

      <Row>
        <Col sm={2}>
          <h6>名前</h6>
        </Col>
        <Col>
          <h4>{user.name}</h4>
        </Col>
      </Row>
      <Row>
        <Col sm={2}>
          <h6>メールアドレス</h6>
        </Col>
        <Col>
          <h4>{user.email}</h4>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm={{ span: 2 }}>
          <Button variant="outline-secondary" onClick={() => history.goBack()}>
            戻る
          </Button>
        </Col>
        <Col sm={{ span: 2 }}>
          <Button onClick={(e: any) => submit(e)}>パスワードを変更する</Button>
        </Col>
      </Row>
    </>
  )
}

export default ProfilePage
