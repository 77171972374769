import React from "react"
import { Formik, useField } from "formik"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import FormGroup from "../components/FormGroup"

type Values = {
  vendorId: string
  appCode: string
  appName: string
  priceFlag: string
  seqFlag: string
  sealFlag: string
  sealPrefix: string
  sealToUserFlag: string
  seriesFlag: string
  issuer: string
  infoUrl: string
}

type OwnProps = {
  initialValues: Values
  validationSchema: any
  onSubmit: (values: Values) => void | Promise<any>
  isEdit?: boolean
}

const AppForm: React.FC<OwnProps> = ({
  initialValues,
  validationSchema,
  onSubmit,
  isEdit
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}>
      {props => (
        <Form noValidate onSubmit={props.handleSubmit}>
          <Form.Group as={Row} controlId="formVendorId">
            <Form.Label column sm={2}>
              ベンダーID
            </Form.Label>
            <Col sm={4}>
              <Form.Control
                readOnly
                type="text"
                defaultValue={props.values.vendorId}
                isInvalid={
                  props.touched.vendorId && !!props.errors.vendorId
                }></Form.Control>
              <Form.Control.Feedback type="invalid">
                {props.errors.vendorId}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <FormGroup
            readOnly={isEdit}
            label="アプリコード"
            name="appCode"
            placeholder="英数字・記号のみ"
            onChange={props.handleChange}
            value={props.values.appCode}
            required
            isInvalid={props.touched.appCode && !!props.errors.appCode}
            message={props.errors.appCode}
          />

          <FormGroup
            label="アプリ名"
            name="appName"
            placeholder="アプリ名"
            onChange={props.handleChange}
            value={props.values.appName}
            required
            isInvalid={props.touched.appName && !!props.errors.appName}
            message={props.errors.appName}
          />

          <FormGroup
            label="発行元"
            name="issuer"
            placeholder="発行元"
            onChange={props.handleChange}
            value={props.values.issuer}
            required
            isInvalid={props.touched.issuer && !!props.errors.issuer}
            message={props.errors.issuer}
          />

          <Form.Group as={Row} controlId="priceFlag">
            <Form.Label column sm={2}>
              価格指定
              <span className="text-danger float-right mr-5">(必須)</span>
            </Form.Label>
            <Col sm={4}>
              <RadioGroup name="priceFlag" />
              {props.touched.priceFlag && !!props.errors.priceFlag && (
                <ErrorMessage message={props.errors.priceFlag} />
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="seqFlag">
            <Form.Label column sm={2}>
              証明書番号設定
              <span className="text-danger float-right mr-5">(必須)</span>
            </Form.Label>
            <Col sm={4}>
              <SeqNumberRadioGroup name="seqFlag" />
              {props.touched.seqFlag && !!props.errors.seqFlag && (
                <ErrorMessage message={props.errors.seqFlag} />
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="sealFlag">
            <Form.Label column sm={2}>
              シールナンバー指定
              <span className="text-danger float-right mr-5">(必須)</span>
            </Form.Label>
            <Col sm={4}>
              <RadioGroup name="sealFlag" />
              {props.touched.sealFlag && !!props.errors.sealFlag && (
                <ErrorMessage message={props.errors.sealFlag} />
              )}
            </Col>
          </Form.Group>

          <FormGroup
            label="シールナンバープレフィックス"
            name="sealPrefix"
            placeholder="５桁の英数字"
            onChange={props.handleChange}
            value={props.values.sealPrefix}
            isInvalid={props.touched.sealPrefix && !!props.errors.sealPrefix}
            message={props.errors.sealPrefix}
          />

          <Form.Group as={Row} controlId="sealToUserFlag">
            <Form.Label column sm={2}>
              シールナンバーとユーザーIDの連携
              <span className="text-danger float-right mr-5">(必須)</span>
            </Form.Label>
            <Col sm={4}>
              <RadioGroup name="sealToUserFlag" />
              {props.touched.sealToUserFlag && !!props.errors.sealToUserFlag && (
                <ErrorMessage message={props.errors.sealToUserFlag} />
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="seriesFlag">
            <Form.Label column sm={2}>
              作品名指定
              <span className="text-danger float-right mr-5">(必須)</span>
            </Form.Label>
            <Col sm={4}>
              <RadioGroup name="seriesFlag" />
              {props.touched.seriesFlag && !!props.errors.seriesFlag && (
                <ErrorMessage message={props.errors.seriesFlag} />
              )}
            </Col>
          </Form.Group>

          <FormGroup
            label="お問い合わせ先URL"
            name="infoUrl"
            placeholder="お問い合わせ先URL"
            onChange={props.handleChange}
            value={props.values.infoUrl}
            isInvalid={props.touched.infoUrl && !!props.errors.infoUrl}
            message={props.errors.infoUrl}
          />

          <Row>
            <Col sm={{ span: 2, offset: 2 }}>
              <Button type="submit" variant="primary">
                登録
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

const ErrorMessage = (props: { message: string }) => <p style={{ color: "#dc3545", fontSize: "80%" }}>{props.message}</p>

/**
 * 汎用ラジオボタン。
 * @param props 
 * @returns 
 */
const RadioGroup = (props: { name: string }) => {
  const [field] = useField(props.name)
  const on = `${props.name}_on`
  const off = `${props.name}_off`

  return (
    <>
      <input
        {...field}
        id={off}
        value="0"
        checked={field.value === "0"}
        name={props.name}
        type="radio"
      />
      <label htmlFor={off} className="mr-3 ml-1">
        指定なし
      </label>

      <input
        {...field}
        id={on}
        value="1"
        name={props.name}
        checked={field.value === "1"}
        type="radio"
      />
      <label htmlFor={on} className="ml-1">
        指定あり
      </label>
    </>
  )
}

/**
 * 証明書番号設定ラジオボタン。
 * @param props 
 * @returns 
 */
const SeqNumberRadioGroup = (props: { name: string }) => {
  const [field] = useField(props.name)
  const on = `${props.name}_on`
  const off = `${props.name}_off`

  return (
    <>
      <input
        {...field}
        id={off}
        value="0"
        checked={field.value === "0"}
        name={props.name}
        type="radio"
      />
      <label htmlFor={off} className="mr-3 ml-1">
        自動採番
      </label>

      <input
        {...field}
        id={on}
        value="1"
        name={props.name}
        checked={field.value === "1"}
        type="radio"
      />
      <label htmlFor={on} className="ml-1">
        個別指定
      </label>
    </>
  )
}

export default AppForm
