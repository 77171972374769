import {
  Cert,
  Certs,
  Histories,
  QueryParams,
  HistoryFilterParams,
  CountTypes
} from "./../types"
import {
  axiosWithToken,
  convertQueryParams,
  convertFilterParams
} from "./../util"
import { AxiosResponse } from "axios"
import { getMonthRange, getLastWeekRange, getLastSixMonthRange } from "../util"
import { fetchAssetCount } from "./app"

export const fetchCert = async (
  token: string,
  certId: string
): Promise<Cert> => {
  const res = await axiosWithToken(token).get(`/admin/certs/${certId}`)
  return res.data
}

export const fetchCerts = async (
  token: string,
  params: {
    vendorId?: string
    appCode?: string
    assetCode?: string
  } & QueryParams
): Promise<Certs> => {
  const { vendorId, appCode, assetCode, page, pageNum, item, order } = params
  const queryParams = convertQueryParams({
    page,
    pageNum,
    item: item === "certId" ? "hashCertId" : item,
    order
  })

  const query =
    queryParams +
    `${vendorId ? "&vendorId=" + vendorId : ""}${
      appCode ? "&appCode=" + params.appCode : ""
    }${assetCode ? "&assetCode=" + assetCode : ""}`
  const res: AxiosResponse<Certs> | void = await axiosWithToken(token).get(
    `/admin/certs?${query}`
  )

  if (!res) {
    return { certs: [], count: 0 }
  }

  return res.data
}

export const deleteCert = async (
  token: string,
  certId: string,
  reason: string
) => {
  await axiosWithToken(token).delete(`/admin/certs/${certId}`, {
    data: {
      reason
    }
  })
}

export const fetchHistory = async (
  token: string,
  params: QueryParams & HistoryFilterParams
): Promise<Histories> => {
  const queryParams = convertQueryParams(params)
  const filterParams = convertFilterParams(params)

  const res: AxiosResponse<Histories> | void = await axiosWithToken(token).get(
    `/admin/certs/history?${queryParams}&${filterParams}`
  )

  if (!res) {
    return { histories: [], count: 0 }
  }

  return res.data
}

export const downloadCSV = async (
  token: string,
  params: HistoryFilterParams
) => {
  const filterParams = convertFilterParams(params)

  const res: AxiosResponse<Blob> | void = await axiosWithToken(
    token,
    "blob"
  ).get(`/admin/certs/history/download?${filterParams}`)

  return res ? res.data : ""
}

const fetchCertsCount = async (token: string, params: HistoryFilterParams) => {
  const filterParams = convertFilterParams(params)
  const res = await axiosWithToken(token).get(
    `/admin/certs/history/count?${filterParams}`
  )
  return res ? res.data : {}
}

export const fetchCountForDashboard = async (
  token: string,
  vendorId: string,
  appCode: string
): Promise<CountTypes> => {
  const date = new Date()
  const assetCount = await fetchAssetCount(token, vendorId, appCode)
  const allHistoriesCounts = await fetchCertsCount(token, {
    vendorId,
    appCode,
    from: null,
    to: null
  })
  const thisMonthCount = await fetchCertsCount(token, {
    vendorId,
    appCode,
    ...getMonthRange(date)
  })
  const lastWeekCount = await fetchCertsCount(token, {
    vendorId,
    appCode,
    group: "date",
    ...getLastWeekRange(date)
  })
  const lastSixMonthCount = await fetchCertsCount(token, {
    vendorId,
    appCode,
    group: "month",
    ...getLastSixMonthRange(date)
  })

  return {
    assetCount,
    allHistoriesCounts,
    thisMonthCount,
    lastWeekCount,
    lastSixMonthCount
  }
}
