import { Asset, Assets, QueryParams } from "./../types"
import { axiosWithToken, convertQueryParams } from "./../util"

export const fetchAsset = async (
  token: string,
  vendorId: string,
  appCode: string,
  assetCode: string
): Promise<Asset> => {
  const res = await axiosWithToken(token).get(
    `/admin/assets/${vendorId}/${appCode}/${assetCode}`
  )
  return res.data
}

export const fetchAssets = async (
  token: string,
  vendorId: string,
  appCode: string,
  params: QueryParams
): Promise<Assets> => {
  const queryParams = convertQueryParams(params)
  const res = await axiosWithToken(token).get(
    `/admin/assets/${vendorId}/${appCode}?` + queryParams
  )
  return res.data
}
