import React from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { Container, Spinner } from "react-bootstrap"
import Header from "./components/Header"
import ListVendorPage from "./pages/ListVendor"
import AddVendorPage from "./pages/AddVendor"
import EditVendorPage from "./pages/EditVendor"
import ListAppPage from "./pages/ListApp"
import AddAppPage from "./pages/AddApp"
import EditAppPage from "./pages/EditApp"
import ListAssetPage from "./pages/ListAsset"
import DetailAssetPage from "./pages/DetailAsset"
import FindCertPage from "./pages/FindCert"
import DetailCertPage from "./pages/DetailCert"
import ProfilePage from "./pages/ProfilePage"
import HistoryPage from "./pages/History"
import NotFoundPage from "./pages/NotFound"
import { useAuth } from "./Auth"
import "./App.css"
import DashboardPage from "./pages/Dashboard"

const App: React.FC = () => {
  const { isAuthenticated } = useAuth()

  if (!isAuthenticated) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status" />
      </div>
    )
  }

  return (
    <React.Fragment>
      <BrowserRouter>
        <Header />
        <Container fluid className="px-3">
          <Switch>
            <Route exact path="/" component={ListVendorPage} />
            <Route exact path="/vendors/:id/edit" component={EditVendorPage} />
            <Route exact path="/add_vendor" component={AddVendorPage} />{" "}
            <Route exact path="/vendors/:id/apps" component={ListAppPage} />
            <Route
              exact
              path="/vendors/:vendorId/add_app"
              component={AddAppPage}
            />
            <Route
              exact
              path="/vendors/:vendorId/apps/:appCode"
              component={DashboardPage}
            />
            <Route
              exact
              path="/vendors/:vendorId/apps/:appCode/edit"
              component={EditAppPage}
            />
            <Route
              exact
              path="/vendors/:vendorId/apps/:appCode/assets"
              component={ListAssetPage}
            />
            <Route
              exact
              path="/vendors/:vendorId/apps/:appCode/assets/:assetCode"
              component={DetailAssetPage}
            />
            <Route exact path="/find_cert" component={FindCertPage} />
            <Route
              exact
              path="/certifications/:id"
              component={DetailCertPage}
            />
            <Route exact path="/profile" component={ProfilePage} />
            <Route exact path="/history" component={HistoryPage} />
            <Route component={NotFoundPage} />
          </Switch>
        </Container>
      </BrowserRouter>
    </React.Fragment>
  )
}

export default App
