import React from "react"
import { ResponsiveBar } from "@nivo/bar"

type OwnProps = {
  data: BarChartData
}

type BarChartData = {
  アクティベート: number
  発行: number
  移転: number
  date: string
}[]

const BarChart = ({ data }: OwnProps) => (
  <ResponsiveBar
    data={data}
    keys={["アクティベート", "発行", "移転"]}
    indexBy="date"
    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
    padding={0.3}
    colors={{ scheme: "category10" }}
    defs={[
      {
        id: "dots",
        type: "patternDots",
        background: "inherit",
        color: "#38bcb2",
        size: 4,
        padding: 1,
        stagger: true
      },
      {
        id: "lines",
        type: "patternLines",
        background: "inherit",
        color: "#eed312",
        rotation: -45,
        lineWidth: 6,
        spacing: 10
      }
    ]}
    borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "",
      legendPosition: "middle",
      legendOffset: 32
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "",
      legendPosition: "middle",
      legendOffset: -40
    }}
    enableLabel={false}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
    legends={[
      {
        dataFrom: "keys",
        anchor: "bottom-left",
        direction: "row",
        justify: false,
        translateX: 0,
        translateY: 42,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 10,
        itemDirection: "left-to-right",
        itemOpacity: 0.85,
        symbolSize: 14,
        effects: [
          {
            on: "hover",
            style: {
              itemOpacity: 1
            }
          }
        ]
      }
    ]}
    animate={true}
//    motionStiffness={90}
//    motionDamping={15}
  />
)

export default BarChart
