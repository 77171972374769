import React, { createContext, useEffect, useContext, useState } from "react"
import createAuth0Client from "@auth0/auth0-spa-js"
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client"
import { User } from "./types"
import { fetchUser } from "./usecases/auth"

type Auth0Options = {
  children: React.ReactNode
  domain: string
  client_id: string
  audience: string
  redirect_uri: string
}

type AuthValue = {
  isLoading: boolean
  isAuthenticated: boolean
  auth0Client: Auth0Client | null
  token: string
  user: User | null
}

const AuthContext = createContext<AuthValue>({} as AuthValue)

export const useAuth = () => useContext<AuthValue>(AuthContext)

export const AuthProvider: React.FC<Auth0Options> = ({
  children,
  ...options
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [auth0Client, setAuth0Client] = useState<Auth0Client | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [token, setToken] = useState("")
  const [user, setUser] = useState<User | null>(null)

  useEffect(() => {
    const f = async () => {
      const auth0Client = await createAuth0Client(options)
      setAuth0Client(auth0Client)

      const isAuthenticated = await auth0Client.isAuthenticated()
      if (!isAuthenticated) {
        await auth0Client.loginWithPopup()
      }
      setIsAuthenticated(true)

      const token = await auth0Client.getTokenSilently()
      setToken(token)

      const auth0User = await auth0Client.getUser()
      //console.log("######### admin auth0User", auth0User)
      if (auth0User && auth0User.sub) {
        const user = await fetchUser(token, auth0User.sub.slice(6))
        //console.log("######### admin user", user)
        setUser(user)
        setIsLoading(false)
      }
    }

    f()
    // eslint-disable-next-line
  }, [])

  return (
    <AuthContext.Provider
      value={{ isLoading, isAuthenticated, auth0Client, token, user }}>
      {children}
    </AuthContext.Provider>
  )
}
