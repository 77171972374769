import * as Yup from "yup"
import validators from "./common"

const appValidator = Yup.object({
  vendorId: validators.vendorId,
  appCode: validators.appCode,
  appName: validators.appName,
  priceFlag: validators.priceFlag,
  seqFlag: validators.seqFlag,
  sealFlag: validators.sealFlag,
  sealPrefix: validators.sealPrefix,
  sealToUserFlag: validators.sealToUserFlag,
  issuer: validators.issuer,
  infoUrl: validators.infoUrl
})

export default appValidator
