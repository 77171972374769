import { useFormik } from "formik"
import React from "react"
import { Card, Form, Button, Col, Row } from "react-bootstrap"
import { RouteComponentProps } from "react-router-dom"
import { useAuth } from "../Auth"
import { useToast } from "../Toast"
import { FailModal, SuccessModal } from "../components/Modal"
import { addVendor } from "../usecases/vendor"
import validator from "../validators/vendor"

const AddVendorPage: React.FC<RouteComponentProps> = ({ history }) => {
  const { token } = useAuth()
  const { createToast, isShow, setIsShow } = useToast()
  const formik = useFormik({
    initialValues: {
      vendorId: "",
      vendorName: "",
      staffName: "",
      staffEmail: ""
    },
    validationSchema: validator,
    onSubmit: function (values) {
      submit(values)
    }
  })

  const submit = async (values: {
    vendorId: string
    vendorName: string
    staffName: string
    staffEmail: string
  }) => {
    const title = "ベンダー情報登録"

    try {
      await addVendor(
        token,
        values.vendorId,
        values.vendorName,
        values.staffName,
        values.staffEmail
      )
      history.push("/")

      setIsShow(true)
      createToast(() => () => (
        <SuccessModal
          show={isShow}
          handleClose={() => setIsShow(false)}
          title={title}
        />
      ))
    } catch (error: any) {
      const errorMessage = error.response.data.message

      setIsShow(true)
      createToast(() => () => (
        <FailModal
          show={isShow}
          handleClose={() => setIsShow(false)}
          title={title}
          message={errorMessage}
        />
      ))
    }
  }

  return (
    <>
      <Row className="mb-4">
        <Col>
          <h2 className="m-0">ベンダー登録</h2>
        </Col>
      </Row>
      <Card>
        <Card.Body>
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Form.Group as={Row} controlId="formVendorId">
              <Form.Label column sm={2}>
                ベンダーID
                <span className="text-danger float-right mr-5">(必須)</span>
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  type="text"
                  name="vendorId"
                  placeholder="ベンダーID"
                  onChange={formik.handleChange}
                  value={formik.values.vendorId}
                  isInvalid={
                    formik.touched.vendorId && !!formik.errors.vendorId
                  }></Form.Control>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.vendorId}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formVendorName">
              <Form.Label column sm={2}>
                ベンダー名
                <span className="text-danger float-right mr-5">(必須)</span>
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  type="text"
                  name="vendorName"
                  placeholder="ベンダー名"
                  onChange={formik.handleChange}
                  value={formik.values.vendorName}
                  isInvalid={
                    formik.touched.vendorName && !!formik.errors.vendorName
                  }
                />
                <Form.Control.Feedback type="invalid">
                  値を入力してください
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formStaffName">
              <Form.Label column sm={2}>
                担当者名
                <span className="text-danger float-right mr-5">(必須)</span>
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  type="text"
                  name="staffName"
                  placeholder="担当者名"
                  onChange={formik.handleChange}
                  value={formik.values.staffName}
                  isInvalid={
                    formik.touched.staffName && !!formik.errors.staffName
                  }
                />
                <Form.Control.Feedback type="invalid">
                  値を入力してください
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formStaffEmail">
              <Form.Label column sm={2}>
                担当者Email
                <span className="text-danger float-right mr-5">(必須)</span>
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  type="email"
                  name="staffEmail"
                  placeholder="担当者Email"
                  onChange={formik.handleChange}
                  value={formik.values.staffEmail}
                  isInvalid={
                    formik.touched.staffEmail && !!formik.errors.staffEmail
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.staffEmail}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Row>
              <Col sm={{ span: 2, offset: 2 }}>
                <Button type="submit" variant="primary">
                  登録
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>

      <Button
        className="mt-4"
        variant="outline-secondary"
        onClick={() => history.goBack()}>
        戻る
      </Button>
    </>
  )
}

export default AddVendorPage
