import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import { RouteComponentProps, Link } from "react-router-dom"
import { fetchCountForDashboard } from "../usecases/certification"
import { useAuth } from "../Auth"
import { Row, Button, Col, Card, Spinner } from "react-bootstrap"
import LineChart from "../components/LineChart"
import BarChart from "../components/BarChart"
import { CountResponse, CountTypes } from "../types"
import { getMonthRangeStr } from "../util"

type MonthRangeStr = {
  from: string
  to: string
}

const DisplayCount = (count: number | undefined, label: string) => (
  <Col>
    <Col>
      <h2 className="text-center">
        {count ?? 0}
      </h2>
    </Col>
    <Col className="text-center font-weight-bold text-nowrap">{label}</Col>
  </Col>
)

const DisplayHistoryCount = (counts: CountResponse) => (
  <React.Fragment>
    {DisplayCount(counts.issue, "発行")}
    {DisplayCount(counts.transfer, "移転")}
    {DisplayCount(counts.activate, "アクティベート")}
  </React.Fragment>
)

const Dashboard: React.FC<
  RouteComponentProps<{ vendorId: string, appCode: string }>
> = ({ match }) => {
  const { vendorId, appCode } = match.params
  const { token } = useAuth()
  const [counts, setCounts] = useState<CountTypes>()
  const [monthRangeStr, setMonthRangeStr] = useState<MonthRangeStr>()

  useEffect(() => {
    const f = async () => {
      const res = await fetchCountForDashboard(token, vendorId, appCode)
      setCounts(res)
    }
    token && f()

    setMonthRangeStr(getMonthRangeStr(new Date()))
  }, [appCode, token, vendorId])

  const h = Object.entries(counts?.lastWeekCount ?? {}).map(([key, value]) => {
    const date = dayjs(key).format("MM/DD")

    return [
      {
        id: "transfer",
        color: "blue",
        data: {
          x: date,
          y: value?.transfer ?? 0
        }
      },
      {
        id: "issue",
        color: "blue",
        data: {
          x: date,
          y: value?.issue ?? 0
        }
      },
      {
        id: "activate",
        color: "blue",
        data: {
          x: date,
          y: value?.activate ?? 0
        }
      },
    ]
  })

  const data = [
    {
      id: "アクティベート",
      color: "blue",
      data: h.flat().filter(a => a.id === "activate").map(a => a.data)
    },
    {
      id: "移転",
      color: "blue",
      data: h.flat().filter(a => a.id === "transfer").map(a => a.data)
    },
    {
      id: "発行",
      color: "blue",
      data: h.flat().filter(a => a.id === "issue").map(a => a.data)
    },
  ]

  const barData = Object.entries(counts?.lastSixMonthCount ?? {}).map(([key, value]) => {
    return {
      "アクティベート": value?.activate ?? 0,
      "発行": value?.issue ?? 0,
      "移転": value?.transfer ?? 0,
      date: dayjs(key).format("YY/MM")
    }
  })

  return (
    <React.Fragment>
      <Row className="m-2">
        <h2>アプリ詳細: {appCode}</h2>
      </Row>
      <Row>
        <Col>
          <Card className="px-4 pt-4">
            <Card.Title className="font-weight-bold">証明書(今月)</Card.Title>
            <Card.Body>
              <Row className="justify-content-center">
                {counts ? DisplayHistoryCount(counts.thisMonthCount) : <Spinner animation="border" />}
              </Row>
              <Row className="mt-4 justify-content-center">
                <Link to={`/history?vendorId=${vendorId}&appCode=${appCode}&from=${monthRangeStr?.from}&to=${monthRangeStr?.to}`}  className="w-100">
                  <Button className="w-100" variant="success">
                    取引一覧
                  </Button>
                </Link>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="px-4 pt-4">
            <Card.Title className="font-weight-bold">証明書(全期間)</Card.Title>
            <Card.Body>
              <Row className="justify-content-center">
                {counts ? DisplayHistoryCount(counts.allHistoriesCounts) : <Spinner animation="border" />}
              </Row>
              <Row className="mt-4 justify-content-center">
                <Link to={`/history?vendorId=${vendorId}&appCode=${appCode}`} className="w-100">
                  <Button className="w-100" variant="success">
                    取引一覧
                  </Button>
                </Link>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="px-4 pt-4">
            <Card.Title className="font-weight-bold">アセット</Card.Title>
            <Card.Body>
              <Row className="justify-content-center">
                {counts ? DisplayCount(counts.assetCount, "個") : <Spinner animation="border" />}
              </Row>
              <Row className="mt-4 justify-content-center">
                <Link to={`/vendors/${vendorId}/apps/${appCode}/assets`} className="w-100">
                  <Button className="w-100" variant="success">
                    アセット一覧
                  </Button>
                </Link>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg={6} xs={12}>
          <Card>
            日別証明書処理数
            <div style={{ maxWidth: "100%", height: "500px" }}>
              <LineChart data={data} />
            </div>
          </Card>
        </Col>
        <Col lg={6} xs={12}>
          <Card>
            月別証明書処理数
            <div style={{ maxWidth: "100%", height: "500px" }}>
              <BarChart data={barData} />
            </div>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Dashboard
