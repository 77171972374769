import React from "react"
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import { Certs, Apps, Cert, LoadFunction } from "../types"
import { toJST } from "../util"
import BaseTable from "./BaseTable"
import { TableColumn } from "react-data-table-component"

interface CertDataRow {
  certId: string
  vendorName: string
  appName: string
  assetName: string
  assetSeq: number
  hashUserId: string
  detailCert: string
  registeredDate: string
//  appCode: string
//  assetCode: string
}

const ListCertTbl = ({ certs, count }: Certs, { apps }: Apps, handleLoad: LoadFunction) => {
  const rows = certs.map((cert: Cert) => {
    const {
      registeredDate,
      certId,
      assetSeq,
      userId,
      assetName,
      appName,
      vendorName
    } = cert

    const detailCert = (
      <Link to={`/certifications/${certId}`}>
        <Button size="sm">詳細</Button>
      </Link>
    )

    return {
      certId: certId,
      vendorName,
      appName,
      assetName,
      assetSeq,
      hashUserId: userId,
      registeredDate: toJST(registeredDate),
      detailCert
    }
  })

  const columns: TableColumn<CertDataRow>[] = [
    {
      name: "証明書ID",
      selector: row => row.certId,
//        selector: "certId",
      sortable: true
    },
    {
      name: "ベンダー名",
//      selector: "vendorName",
      selector: row => row.vendorName,
      sortable: true
    },
    {
      name: "アプリ名",
//      selector: "appName",
      selector: row => row.appName,
      sortable: true
    },
    {
      name: "アセット名",
//      selector: "assetName"
      selector: row => row.assetName,
    },
    {
      name: "証明書番号",
//      selector: "assetSeq",
      selector: row => row.assetSeq,
      sortable: true,
      width: "100px"
    },
    {
      name: "所有者ID",
//      selector: "hashUserId"
      selector: row => row.hashUserId,
    },
    {
      name: "発行日",
//      selector: "registeredDate",
      selector: row => row.registeredDate,
      sortable: true,
      width: "175px"
    },
    {
      name: "",
//      selector: "detailCert",
      selector: row => row.detailCert,
      width: "80px"
    }
  ]

  return (
    <BaseTable<CertDataRow>
      data={{
        columns: columns,
        data: rows
      }}
      count={count}
      handleLoad={handleLoad}
    />
  )
}

export default ListCertTbl
